import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import ElectionIncidentFormFields from '../../templates/electionIncidentForm/ElectionIncidentFormFields';
import useUserData from '../../../services/auth/useUserData';
import { useParams } from 'react-router-dom';
import { API_URL, AWS_URL, sendAuthenticatedGetRequest } from '../../../services/http';

const ElectionIncidentForm: React.FC = () => {
  const { userData } = useUserData();
  const { electionIncidentId }: any = useParams();

  const [incidentData, setIncidentData] = useState<any>();

  // below useEffect needs to be changed.
  useEffect(() => {
    (async () => {
      if (electionIncidentId) {
        await sendAuthenticatedGetRequest(
          `${API_URL}/election-incident/${electionIncidentId}`
        ).then((res: any) => {
          const targetCategory = res.data?.targetOptions;
          const formattedTargetCategory: any = {};
          targetCategory.forEach((item: any) => {
            const targetId = item.target.targetId;
            formattedTargetCategory[targetId]
              ? formattedTargetCategory[targetId].push(item.targetOptionId)
              : (formattedTargetCategory[targetId] = [item.targetOptionId]);
          });

          const electionCategoryTypes = res.data?.electionIncidentCategoryTypes;
          const formattedElectionCategoryTypes: any = [];
          electionCategoryTypes.forEach((item: any) => {
            formattedElectionCategoryTypes.push(item.electionCategory?.electionCategoryId);
          });

          const electionAffectedParties = res.data?.affectedParties;
          const formattedElectionAffectedParties: any = [];
          electionAffectedParties.forEach((item: any) => {
            formattedElectionAffectedParties.push(item.electionParty?.electionPartyId);
          });

          const data = [
            {
              link: res.data?.link,
              pageLink: res.data?.pageLink,
              description: res.data?.description,
              category: res.data?.electionStage?.electionStageCategoryId,
              categoryTypes: formattedElectionCategoryTypes,
              natureOfTheComplaint: res.data?.electionComplaintNature?.electionComplaintNatureId,
              affectedParties: formattedElectionAffectedParties,
              targetCategory: [formattedTargetCategory],
              genderBasedViolence: res.data?.genderBasedViolence,
              perpetratorGender: res.data?.gender?.genderId,
              engagement: res.data?.engagement,
              views: res.data?.views,
              source: res.data?.source?.sourceId,
              screenshot: res.data?.incidentImage ? `${AWS_URL}/${res.data.incidentImage}` : null,
              screenshotId: res.data?.screenshotId || '',
              date: res.data?.date,
              country: res.data?.country?.countryName,
              province: res.data?.location?.regionName,
              district: res.data?.location?.subRegionCode,
              language: res.data?.language?.languageId,
              contentDetectedAsCIB: res.data?.electionCIB?.electionCIBId,
              contentPublishedSupportOf: res.data?.contentSupportOf?.electionPartyId ?? '',
              typeOfContent: res.data?.socialMediaPlatformContent?.socialMediaPlatformContentId,
              notes: res.data?.notes,
            },
            {
              incidentStatus: res.data?.incidentStatus,
              reportedByName: res.data?.reportedBy?.name,
              reportedByUserId: res.data?.reportedBy?.userId,
            },
          ];
          setIncidentData(data);
        });
      }
    })();
  }, []);

  return (
    <Layout>
      <ElectionIncidentFormFields userData={userData} incidentData={incidentData} />
    </Layout>
  );
};

export default ElectionIncidentForm;
