import { useRef } from 'react';
import ReactECharts from 'echarts-for-react';

interface Props {
  chartData?: any;
  height: string;
  chartTitle?: string;
  isLoading?: boolean;
}

const Chart = ({ chartData, chartTitle, isLoading, height }: Props) => {
  const echartRef = useRef(null as any);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    dataZoom: null,
    grid: {
      left: '5%',
      right: '2%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
        rotate: 90,
        inside: true,
        color: '#ffffff',
        fontWeight: 450,
        fontSize: 13,
        margin: 15,
      },
      z: 10,
      data: chartData?.xAxisData ? chartData.xAxisData : [],
      name: '',
      nameLocation: 'end',
      nameGap: -100,
      boundaryGap: true,
      nameTextStyle: {
        fontWeight: 'bolder',
        verticalAlign: 'top',
        lineHeight: 80,
        fontSize: 15,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      name: 'No. of Incidents',
      nameGap: 25,
      nameTextStyle: {
        fontWeight: 'bolder',
        fontSize: 15,
        align: 'left',
        verticalAlign: 'bottom',
        color: '#ffffff',
      },
      axisLabel: {
        color: '#ffffff',
      },
      splitLine: {
        show: false,
      },
    },
    series: {
      id: 'targetGroup',
      name: 'Entries',
      type: 'bar',
      barWidth: '60%',
      data: chartData?.columnData ? chartData.columnData : [],
      showBackground: true,
    },
  };

  const onChartClick = (event: any) => {
    if (event.data) {
      const subData: any = chartData.drillDownData.find(function (data: any) {
        return data.dataGroupId === event.data.groupId;
      });
      if (!subData) {
        return;
      }
      echartRef.current.getEchartsInstance().setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        dataZoom: null,
        grid: {
          left: '5%',
          right: '2%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 90,
            inside: true,
            color: '#ffffff',
            fontWeight: 450,
            fontSize: 13,
            margin: 15,
          },
          z: 10,
          data: subData.data.map(function (item: any) {
            return item[0];
          }),
          name: '',
          nameLocation: 'end',
          nameGap: -100,
          boundaryGap: true,
          nameTextStyle: {
            fontWeight: 'bolder',
            verticalAlign: 'top',
            lineHeight: 80,
            fontSize: 15,
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          name: event.data.groupId,
          nameGap: 25,
          nameTextStyle: {
            fontWeight: 'bolder',
            fontSize: 15,
            align: 'left',
            verticalAlign: 'bottom',
            color: '#ffffff',
          },
          axisLabel: {
            color: '#ffffff',
          },
          splitLine: {
            show: false,
          },
        },
        series: {
          type: 'bar',
          id: 'targetGroup',
          dataGroupId: subData.dataGroupId,
          data: subData.data.map(function (item: any) {
            return {
              value: item[1],
              itemStyle: { color: '#5FBEE5' },
            };
          }),
          universalTransition: {
            enabled: true,
            divideShape: 'clone',
          },
          showBackground: true,
        },
      });
    }
  };

  const onEvents = {
    click: onChartClick,
  };

  const customLoadingOption = {
    textColor: '#ffffff',
    maskColor: 'rgba(0, 0, 0, 0.1)',
  };

  return (
    <div className="rounded-md p-6 pl-4 m-2">
      {chartTitle && <div className="font-semibold text-lg mb-4 text-white">{chartTitle}</div>}
      <div className="grid grid-cols-2">
        <ReactECharts
          showLoading={isLoading}
          loadingOption={customLoadingOption}
          option={option}
          style={{ height: height }}
          onEvents={onEvents}
        />
        <ReactECharts option={{}} style={{ height: height }} onEvents={onEvents} ref={echartRef} />
      </div>
    </div>
  );
};

export default Chart;
