import DashboardCharts from '../../templates/dashboard';
import FilterForm from '../../templates/dashboard/filterForm';
import Layout from '../Layout';

const ElectionDashboard = () => {
  return (
    <Layout>
      <div className="gradient-background">
        <div className="w-full">
          <span className="font-semibold text-2xl text-white">Election Dashboard</span>
          <FilterForm filterFrom="ELECTION_DASHBOARD" />
          <DashboardCharts dashboardFor="ELECTION_DASHBOARD" />
        </div>
      </div>
    </Layout>
  );
};

export default ElectionDashboard;
