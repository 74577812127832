import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Fade, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { IoMdDownload } from 'react-icons/io';
import { GridRenderCellParams } from '@mui/x-data-grid';

import useUserData from '../../../services/auth/useUserData';
import {
  API_URL,
  sendAuthenticatedDeleteRequest,
  sendAuthenticatedGetRequest,
} from '../../../services/http';
import { TOAST_STATUS, USER_ROLE_ADMIN } from '../../../Constants';

import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DataTable from '../../ui/dataTable/index';
import Toastr from '../../ui/toastr';
import { FaEdit, FaTrash } from 'react-icons/all';

const Index: React.FC = () => {
  const { userData } = useUserData();

  const [searchText, setSearchText] = useState<string>();
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'createdDate' });
  const [showDownloadField, setShowDownloadField] = useState(false);
  const [showDownloadCountField, setShowDownloadCountField] = useState(true);
  const [showAlert, setShowAlert] = React.useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const getPDF = async (id: any) => {
    await sendAuthenticatedGetRequest(`${API_URL}/reports/${id}/download`)
      .then((res: any) => {
        const pdfWindow: any = window.open();
        pdfWindow.location.href = res.data.url;
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const Download = (id: any) => {
    const handleDownload = () => {
      getPDF(id.id);
    };
    return (
      <FormControlLabel
        control={
          <Tooltip
            title="Download"
            placement="right"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <IconButton onClick={handleDownload}>
              <IoMdDownload />
            </IconButton>
          </Tooltip>
        }
        label={''}
      />
    );
  };

  const columnData = [
    {
      field: 'name',
      headerName: 'Report Title',
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        const handleDelete = async (id: number, name: string) => {
          const confirmed = window.confirm(
            'Are you sure you want to delete this report "' + name + '" ?'
          );
          if (!confirmed) return;

          try {
            await sendAuthenticatedDeleteRequest(`${API_URL}/reports/${id}`);
            setToastMessage('Report deleted successfully');
            setSeverity('success');
            setShowAlert(true);
            // reload the data
            setUrlParams((prevParams: any) => ({ ...prevParams }));
          } catch (error) {
            console.error('Error deleting report:', error);
            setToastMessage('Failed to delete the report');
            setSeverity('error');
            setShowAlert(true);
          }
        };

        return (
          <div className="flex justify-between w-full">
            <span>{params.value}</span>
            {userData.role === USER_ROLE_ADMIN && (
              <div className="flex justify-end">
                <Tooltip title="Edit">
                  <Link to={`/reports/add?id=${params.row.id}`}>
                    <FaEdit className="ml-2 cursor-pointer text-blue-800" />
                  </Link>
                </Tooltip>
                <Tooltip title="Delete">
                  <FaTrash
                    onClick={() => handleDelete(params.row.id, params.row.name)}
                    className="ml-2 cursor-pointer text-red-600"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Time Period',
      flex: 1,
      filterable: false,
      sortable: false,
    },

    {
      field: 'downloadCount',
      headerName: 'Download Count',
      flex: 1,
      filterable: false,
      sortable: false,
      hide: showDownloadCountField,
    },
    {
      field: 'createdDate',
      headerName: 'Date added',
      flex: 1,
      filterable: false,
      sortable: false,
      type: 'date',
      renderCell: (params: GridRenderCellParams<Date>) => formatDate(params.value),
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      hide: showDownloadField,
      renderCell: (params: any) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Download id={params.row.id} />
          </div>
        );
      },
    },
  ];

  const formatDate = (date: any) => {
    return date.substring(0, 10);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const location: any = useLocation();

  useEffect(() => {
    let params = {};

    params = { sortOrder: 'DESC', sortKey: 'createdDate' };
    if (searchText) {
      params = { sortOrder: 'DESC', sortKey: 'createdDate', searchParam: searchText.trim() };
    }
    setUrlParams(params);
    if (userData.role == USER_ROLE_ADMIN) {
      setShowDownloadField(true);
      setShowDownloadCountField(false);
    }
  }, [searchText, userData.role]);

  useEffect(() => {
    if (location.state != null) {
      if (location.state.toastStatus == TOAST_STATUS) {
        setShowAlert(true);
        setToastMessage(location.state.toastMessage);
        setSeverity(location.state.severity);
      }
    }
  }, [location]);

  return (
    <Layout>
      <div className="gradient-background h-full">
        <div className="w-full">
          <span className="font-semibold text-white text-2xl">Reports</span>
          <div className="flex justify-between items-center my-8">
            <InputField
              isHorizontal={false}
              value={searchText}
              onChangeHandler={(searchItem: any) => setSearchText(searchItem.target.value)}
              placeholder="Search..."
              containerClassNames="w-60"
              inputClassNames="muiWhiteInput"
            />
            <div>
              {userData.role === USER_ROLE_ADMIN && (
                <Link to="/reports/add">
                  <button
                    type="button"
                    className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                  >
                    Add Report
                  </button>
                </Link>
              )}
            </div>
          </div>
          <DataTable
            url="/reports"
            responseProperty={'reports'}
            rowIdProperty={'reportId'}
            urlParams={urlParams}
            rowsPerPage={5}
            columnData={columnData}
            theme={'dark'}
          />
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity && severity}
            message={toastMessage}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Index;
