import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../Layout';
import InputField from '../../ui/inputField';
import DataTable from '../../ui/dataTable/index';
import Toastr from '../../ui/toastr';
import ToggleSwitch from './UserStatusToggle';

import { TOAST_STATUS } from '../../../Constants';
import { FaEdit } from 'react-icons/all';
import { Tooltip } from '@mui/material';

const Index: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'joinedDate' });
  const [showAlert, setShowAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'error'>('success');

  const columnData = [
    {
      field: 'userId',
      headerName: 'User ID',
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: { value: string }) => (
        <div className="flex justify-between w-full">
          <span>{params.value}</span>
          <Tooltip title="Edit User" arrow placement={'top-start'}>
            <Link to={`/users/edit/${params.value}`}>
              <FaEdit className="ml-2 cursor-pointer text-blue-800" />
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'activated',
      headerName: 'Activated',
      sortable: false,
      renderCell: (params: { row: { status: string; userId: string } }) => {
        return (
          <ToggleSwitch
            status={params.row.status}
            userId={params.row.userId}
            onSuccess={(message, severity) => {
              setShowAlert(true);
              setToastMessage(message);
              setSeverity(severity);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    let params = {};

    params = { sortOrder: 'DESC', sortKey: 'joinedDate' };

    if (searchText) {
      params = { sortOrder: 'DESC', sortKey: 'joinedDate', searchParam: searchText.trim() };
    }
    setUrlParams(params);
  }, [searchText]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
  };

  const location: any = useLocation();

  useEffect(() => {
    if (location.state != null) {
      if (location.state.toastStatus == TOAST_STATUS) {
        setShowAlert(true);
        setToastMessage(location.state.toastMessage);
        setSeverity(location.state.severity);
      }
    }
  }, [location]);

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Users</span>
          <div className="flex justify-between items-center my-8">
            <InputField
              isHorizontal={false}
              value={searchText}
              onChangeHandler={(email: any) => setSearchText(email.target.value)}
              placeholder="Search..."
              containerClassNames="w-60"
            />
            <div>
              <Link to="/users/add">
                <button
                  type="button"
                  className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                >
                  Add New User
                </button>
              </Link>
            </div>
          </div>
          <DataTable
            url="/users"
            responseProperty={'users'}
            rowIdProperty={'userId'}
            urlParams={urlParams}
            rowsPerPage={5}
            columnData={columnData}
          />
          <Toastr
            autoHideDuration={6000}
            onClose={handleClose}
            open={showAlert}
            severity={severity}
            message={toastMessage}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Index;
