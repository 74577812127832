import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';

import { RootState } from '../../../redux/reducers';
import { getCharts, setFilterDates } from '../../../redux/actions/dashboard';
import { API_URL, sendAuthenticatedGetRequest, sendGetRequest } from '../../../services/http';

import DatePicker from '../../ui/datePicker';
import DropDownField from '../../ui/dropdown';

import Popup from '../../ui/popup';
import Chart from '../../ui/chart';
import { SM_CONTENT_NAME, SM_PLATFORM_NAME } from '../../../Constants';

interface FilterProps {
  filterFrom?: string;
}

const FilterForm = ({ filterFrom = 'MAIN_DASHBOARD' }: FilterProps) => {
  const dispatch = useDispatch();
  const isChartsLoading = useSelector((state: RootState) => state.dashboard.isChartsLoading);

  const fromDate = new Date(new Date().setDate(new Date().getDate() - 30))
    .toISOString()
    .split('T')[0];
  const initialfromDate = new Date(new Date().setDate(new Date().getDate() - 30));
  const initialToDate = new Date();
  const toDate = new Date().toISOString().split('T')[0];
  const [facebookOptions, setFacebookOptions] = useState<any>([]);
  const [youtubeOptions, setYoutubeOptions] = useState<any>([]);
  const [twitterOptions, setTwitterOptions] = useState<any>([]);
  const [tiktokOptions, setTiktokOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [fromDateValue, setFromDateValue] = React.useState<Date | null>(initialfromDate);
  const [toDateValue, setToDateValue] = React.useState<Date | null>();

  const [openPopup, setOpenPopup] = useState(false);

  const [obKeyNames, setObKeyNames] = useState();
  const [obCount, setObCount] = useState();

  async function getKeyObservations() {
    const observationData: any = await sendAuthenticatedGetRequest(`${API_URL}/observations/top`);
    const xAxisData: any = [];
    const columnData: any = [];

    if (observationData.data.observations) {
      Object.entries(observationData.data.observations).map((item: any) => {
        xAxisData.push(item[1].observationKeyName);
        columnData.push(item[1].observationCount);
      });
      setObKeyNames(xAxisData);
      setObCount(columnData);
    }
  }
  const formattedData: any = {
    keyObservationSettings: {
      xAxisData: obCount,
      xAxisName: '',
      yAxisName: '',
      columnData: obKeyNames,
      xAxisLabelColor: '#ffffff',
      yAxisLabelColor: '#ffffff',
      seriesLabelColor: '#ffffff',
    },
  };

  // Note: Move API Keys to seperate file
  // youtube
  async function getMostPopularVideo() {
    const params = {
      part: 'snippet,contentDetails,statistics',
      chart: 'mostPopular',
      regionCode: 'US',
      maxResults: '10',
      key: 'AIzaSyDT1zdEIk9xBVutAZSPN8tvTtXo1nwi6i0',
    };
    const PopularVideos: any = await sendGetRequest(
      `https://www.googleapis.com/youtube/v3/videos`,
      params
    );

    console.log('Popular Videos', PopularVideos.data.items);
  }

  async function searchVideo() {
    const params = {
      part: 'snippet',
      q: 'srilanka',
      maxResults: '10',
      key: 'AIzaSyDT1zdEIk9xBVutAZSPN8tvTtXo1nwi6i0',
    };
    const SearchResult: any = await sendGetRequest(
      `https://www.googleapis.com/youtube/v3/search`,
      params
    );

    console.log('Search Result', SearchResult.data.items);
  }

  useEffect(() => {
    dispatch(setFilterDates({ startDate: fromDate, endDate: toDate }));
    getFilterIDs();
    getKeyObservations();
    getMostPopularVideo();
    searchVideo();
  }, []);

  useEffect(() => {
    if (isChartsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isChartsLoading]);

  const getFilterIDs = async () => {
    const categoryReq = sendAuthenticatedGetRequest(`${API_URL}/categories`);
    const socialMediaReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);
    const electionCategoryList = sendAuthenticatedGetRequest(`${API_URL}/electionStages`);

    await axios.all([categoryReq, socialMediaReq, electionCategoryList]).then(
      axios.spread(function (categoryReq: any, socialMediaReq: any, electionCategoryList: any) {
        const smTypes = socialMediaReq.data.socialMediaPlatformContents;
        setFacebookOptions(smTypes.filter((item: any) => item?.platform === 'Facebook'));
        setYoutubeOptions(smTypes.filter((item: any) => item?.platform === 'YouTube'));
        setTwitterOptions(smTypes.filter((item: any) => item?.platform === 'Twitter'));
        setTiktokOptions(smTypes.filter((item: any) => item?.platform === 'TikTok'));

        if (filterFrom === 'ELECTION_DASHBOARD') {
          setCategoryOptions(electionCategoryList.data.electionStages);
        } else {
          setCategoryOptions(categoryReq.data.categories);
        }
      })
    );
  };

  return (
    <div className="font-semibold text-md px-4 my-8">
      <div className="mt-5 flex flex-row-reverse">
        <button
          className="bg-indigo-500 focus:outline-none w-40 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
          onClick={() => setOpenPopup(true)}
        >
          Key Observations
        </button>
      </div>
      <Formik
        initialValues={{
          fromDate: fromDate,
          toDate: toDate,
          faceBookReports: [],
          youtubeReports: [],
          twitterReports: [],
          tiktokReports: [],
          categories: [],
        }}
        onSubmit={async values => {
          setLoading(true);
          const startTime = new Date(values.fromDate).getTime();
          const endTime = new Date(values.toDate).setUTCHours(24, 0, 0, -1);

          const filters: any = {
            smPlatformName: SM_PLATFORM_NAME,
            smpContentName: SM_CONTENT_NAME,
            startTimeMillis: startTime,
            endTimeMillis: endTime,
          };

          const selectedCategoryFilters = values.categories.join();
          const selectedSMFilters = [
            ...values.faceBookReports,
            ...values.twitterReports,
            ...values.tiktokReports,
            ...values.youtubeReports,
          ].join();

          if (selectedCategoryFilters.length !== 0) {
            filters['categoryIds'] = selectedCategoryFilters;
          }
          if (selectedSMFilters.length !== 0) {
            filters['socialMediaPlatformContentId'] = selectedSMFilters;
          }
          dispatch(setFilterDates({ startDate: values.fromDate, endDate: values.toDate }));
          dispatch(getCharts(filterFrom, filters));
        }}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form className="m-2 rounded-md">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-8">
              <DatePicker
                title="From"
                inputFormat="dd/MM/yyyy"
                value={fromDateValue}
                onChangeHandler={(e: any) => {
                  setFromDateValue(e);
                  setFieldValue('fromDate', moment(e).format('YYYY-MM-DD'));
                }}
                isVertical={true}
                titleClassNames="text-white"
                inputContainerClassNames="muiWhiteInput"
              />
              <DatePicker
                title="To"
                inputFormat="dd/MM/yyyy"
                value={toDateValue}
                onChangeHandler={(e: any) => {
                  setToDateValue(e);
                  setFieldValue('toDate', moment(e).format('YYYY-MM-DD'));
                }}
                isVertical={true}
                titleClassNames="text-white"
                inputContainerClassNames="muiWhiteInput"
              />
            </div>
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-8">
              <DropDownField
                title="Content on Facebook"
                isMultiple={true}
                isVertical={true}
                value={values.faceBookReports}
                data={facebookOptions}
                onChangeHandler={handleChange('faceBookReports')}
                titleClassNames="text-white"
                inputClassNames="w-full muiWhiteInput"
              />
              <DropDownField
                title="Content on Youtube"
                isMultiple={true}
                isVertical={true}
                value={values.youtubeReports}
                data={youtubeOptions}
                onChangeHandler={handleChange('youtubeReports')}
                titleClassNames="text-white"
                inputClassNames="w-full muiWhiteInput"
              />
              <DropDownField
                title="Content on Twitter"
                isMultiple={true}
                isVertical={true}
                data={twitterOptions}
                value={values.twitterReports}
                onChangeHandler={handleChange('twitterReports')}
                titleClassNames="text-white"
                inputClassNames="w-full muiWhiteInput"
              />
              <DropDownField
                title="Content on Tiktok"
                isMultiple={true}
                isVertical={true}
                data={tiktokOptions}
                value={values.tiktokReports}
                onChangeHandler={handleChange('tiktokReports')}
                titleClassNames="text-white"
                inputClassNames="w-full muiWhiteInput"
              />
              <DropDownField
                title="Category"
                isMultiple={true}
                isVertical={true}
                data={categoryOptions}
                value={values.categories}
                onChangeHandler={handleChange('categories')}
                titleClassNames="text-white"
                inputClassNames="w-full muiWhiteInput"
              />
            </div>
            <div className="mt-5 flex flex-row-reverse">
              <button
                className="bg-blue-900 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 flex justify-center items-center"
                onClick={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress color="inherit" size={20} /> : 'Filter'}
              </button>
              <button
                className="bg-gray-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base flex justify-center items-center"
                onClick={e => {
                  e.preventDefault();
                  setFromDateValue(initialfromDate);
                  setToDateValue(initialToDate);
                  setFieldValue('fromDate', fromDate);
                  setFieldValue('toDate', toDate);
                  setFieldValue('faceBookReports', []);
                  setFieldValue('youtubeReports', []);
                  setFieldValue('twitterReports', []);
                  setFieldValue('tiktokReports', []);
                  setFieldValue('categories', []);
                  handleSubmit();
                }}
                type="button"
              >
                Reset
              </button>
            </div>
          </form>
        )}
      </Formik>
      <Popup
        openPopup={openPopup}
        title="Key Observations for the Week"
        setOpenPopup={setOpenPopup}
        titleClassNames="flex justify-center items-center font-bold text-white"
        dialogClassNames="gradient-background"
        dialogWrapperWidth="1150px"
      >
        <Chart
          chartType="horizontal"
          height="400px"
          settings={formattedData.keyObservationSettings}
        />
      </Popup>
    </div>
  );
};

export default FilterForm;
