import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Field } from 'formik';

interface RadioOption {
  id: string | number;
  name: string;
  disabled?: boolean;
}

interface Props {
  title: string;
  name?: string;
  data?: RadioOption[];
  value?: any;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  titleClassNames?: string;
  inputClassNames?: string;
  containerClassNames?: string;
  onChangeHandler?: ((event: any) => void) | ((event: any) => void)[]; // Can accept one function or an array of functions
  formikError?: any;
  formikTouch?: any;
}

const RadioGroupField: React.FC<Props> = ({
  title,
  name,
  data = [],
  value,
  isRequired = false,
  isDisabled = false,
  isVertical = false,
  titleClassNames = '',
  inputClassNames = '',
  containerClassNames = '',
  onChangeHandler,
  formikError,
  formikTouch,
}) => {
  // Create a function to call each function in onChangeHandler if it's an array
  const handleOnChange = (event: any) => {
    if (Array.isArray(onChangeHandler)) {
      onChangeHandler.forEach(handler => handler(event)); // Call each function in the array
    } else if (onChangeHandler) {
      onChangeHandler(event); // If it's a single function, call it directly
    }
  };
  return (
    <div
      className={`flex flex-row 
        ${containerClassNames ? containerClassNames : `mt-5`} 
        ${isVertical ? `flex-col items-start` : ``}`}
    >
      <div
        className={`font-medium text-base flex-shrink-0 mr-5 
        ${titleClassNames ? titleClassNames : 'w-48'}`}
      >
        {title}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="w-full">
        <FormControl component="fieldset">
          <Field>
            {({ field }: any) => (
              <RadioGroup
                {...field}
                value={value}
                onChange={handleOnChange}
                row={!isVertical}
                disabled={isDisabled}
                className={inputClassNames ? inputClassNames : 'w-full'}
              >
                {data?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                    disabled={item.disabled || isDisabled}
                  />
                ))}
              </RadioGroup>
            )}
          </Field>
        </FormControl>
        {formikError && formikTouch && (
          <div className="text-red-500 text-xs mt-1">{formikError}</div>
        )}
      </div>
    </div>
  );
};

export default RadioGroupField;
