import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import InputField from '../../ui/inputField';
import FilePicker from '../../ui/filePicker';
import DropDownField from '../../ui/dropdown';
import RadioGroupField from '../../ui/RadioGroupField/Index';
import DatePicker from '../../ui/datePicker';
import * as Yup from 'yup';

import { Box, CircularProgress, Stack } from '@mui/material';
import moment from 'moment';
import TextArea from '../../ui/textArea';
import { useHistory, useParams } from 'react-router-dom';
import {
  ADD_INCIDENT,
  ADD_INCIDENT_FAILED,
  ADD_INCIDENT_SUCCESS,
  COUNTRY_SRILANKA,
  SRILANKA_DISTRICTS,
  SRILANKA_PROVINCES,
  STATUS_APPROVED,
  STATUS_NONE,
  STATUS_PENDING,
  STATUS_REJECTED,
  TOAST_STATUS,
  UPDATE_INCIDENT_FAILED,
  UPDATE_INCIDENT_SUCCESS,
  USER_ROLE_ADMIN,
  USER_ROLE_MONITOR,
} from '../../../Constants';
import axios from 'axios';
import {
  API_URL,
  sendAuthenticatedGetRequest,
  sendAuthenticatedPostRequest,
  sendAuthenticatedPutRequest,
  sendGetRequest,
} from '../../../services/http';
import { sortArrayAscending } from '../../../services/common';
import { getCountryNames } from '../../../services/country';

const ElectionIncidentSchema = Yup.object({
  link: Yup.string()
    .required('Link is required')
    .max(2048, 'Link may not be longer than 2048 characters'),

  pageLink: Yup.string()
    .required('Page/Account link is required')
    .max(2048, 'Page/Account link may not be longer than 2048 characters'),

  description: Yup.string()
    .required('Description is required')
    .max(1500, 'Description may not be longer than 1500 characters'),

  category: Yup.string().required('Category is required'),

  categoryTypes: Yup.array()
    .min(1, 'At least one category type is required')
    .required('Category Types are required'),

  natureOfTheComplaint: Yup.string().required('Nature of the complaint is required'),

  contentDetectedAsCIB: Yup.string().required('Content detected as CIB is required'),

  screenshot: Yup.mixed()
    .test('required', 'Screenshot is required', value => {
      // Allow a string (URL) or a File object
      return value && (typeof value === 'string' || value instanceof File);
    })
    .test('fileSize', 'File size is too large', value => {
      if (value instanceof File) {
        return value.size <= 5000000; // Limit size to 5MB
      }
      return true; // Skip validation for strings (URLs)
    }),

  source: Yup.string().required('Source is required'),
  date: Yup.string().required('Date is required'),
  language: Yup.string().required('Language is required'),
  typeOfContent: Yup.string().required('Type of content is required'),
  country: Yup.string().required('Country is required'),

  screenshotId: Yup.string()
    .notRequired()
    .max(150, 'Screenshot ID may not be longer than 150 characters'),
});

interface Props {
  userData?: any;
  incidentData?: any;
}

const ElectionIncidentFormFields: React.FC<Props> = ({ userData, incidentData }) => {
  const history = useHistory();
  const { electionIncidentId }: any = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [recorderByUserId, setRecorderByUserId] = useState<string>('');
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
  const [reportedToSMDisabled, setReportedToSMDisabled] = useState<boolean>(false);
  const [responseDisabled, setResponseDisabled] = useState<boolean>(false);
  const [timeForResponseDisabled, setTimeForResponseDisabled] = useState<boolean>(false);

  const [hasFormikStateChanged, setHasFormikStateChanged] = useState<boolean>(false);
  const [notesDisabled, setNotesDisabled] = useState<boolean>(false);

  const [image, setImage] = useState<any>();
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryTypes, setCategoryTypes] = useState<any>([]);
  const [targetCategoryList, setTargetCategoryList] = useState<any>([]);
  const [targetCategoryInitialState, setTargetCategoryInitialState] = useState<any>({});
  const [languageList, setLanguageList] = useState<any>([]);
  const [sourceList, setSourceList] = useState<any>([]);
  const [affectedParties, setAffectedParties] = useState<any>([]);
  const [contentPublishedSupportOf, setContentPublishedSupportOf] = useState<any>([]);
  const [typeOfContentList, setTypeOfContentList] = useState<any>([]);
  const [perpetratorGenderList, setPerpetratorGenderList] = useState<any>([]);
  const [natureOfTheComplaintList, setNatureOfTheComplaintList] = useState<any>([]);
  const [electionCIBsList, setElectionCIBsList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);

  const [natureOfTheComplaint, setNatureOfTheComplaint] = useState<string | number | null>(null);
  const [showTargetCateSection, setShowTargetCateSection] = useState<boolean>(false);

  const [showEditRejectApproveAction, setShowEditRejectApproveAction] = useState<boolean>(false);
  const [showEditCancelApproveAction, setShowEditCancelApproveAction] = useState<boolean>(false);
  const [showAddAction, setShowAddAction] = useState<boolean>(false);
  const [showAddApproveAction, setShowAddApproveAction] = useState<boolean>(false);
  const [showBackAction, setShowBackAction] = useState<boolean>(false);
  const [showSaveAction, setShowSaveAction] = useState<boolean>(false);
  const [showSaveApproveAction, setShowSaveApproveAction] = useState<boolean>(false);
  const [adminEditFields, setAdminEditFields] = useState<boolean>(false);

  const formikInitialState = {
    link: '',
    pageLink: '',
    description: '',
    category: '',
    categoryTypes: [],
    screenshot: null,
    screenshotId: '',
    natureOfTheComplaint: '',
    contentDetectedAsCIB: '',
    genderReportCategory: [],
    targetCategory: [targetCategoryInitialState],
    genderBasedViolence: '',
    perpetratorGender: '',
    engagement: '',
    views: '',
    language: '',
    source: '',
    affectedParties: [],
    contentPublishedSupportOf: '',
    typeOfContent: '',
    country: '',
    province: '',
    district: '',
    date: '',
    notes: '',
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    if (natureOfTheComplaint === 'ELCCMPLNTR926270236312618') {
      setShowTargetCateSection(true);
    } else {
      setShowTargetCateSection(false);
    }
  }, [natureOfTheComplaint, incidentData]);

  useEffect(() => {
    if (incidentData) {
      setRecorderByUserId(incidentData[1].reportedByUserId);
      setHasFormikStateChanged(true);
      if (incidentData[0].screenshot) {
        setImage(incidentData[0].screenshot);
      }
      if (incidentData[0].natureOfTheComplaint) {
        setNatureOfTheComplaint(incidentData[0].natureOfTheComplaint);
      }
    }
  }, [incidentData]);

  useEffect(() => {
    if (incidentData) {
      if (incidentData[1].incidentStatus === STATUS_PENDING) {
        setFieldDisabled(true);
        setReportedToSMDisabled(true);
        setResponseDisabled(true);
        setTimeForResponseDisabled(true);
        setNotesDisabled(true);

        //If pending and incident reported by logged in monitor
        if (userData.role === USER_ROLE_MONITOR && userData.userId === recorderByUserId) {
          setFieldDisabled(false);
          setReportedToSMDisabled(false);
          setResponseDisabled(false);
          setTimeForResponseDisabled(false);
          setNotesDisabled(false);
        }
      }
      if (incidentData[1].incidentStatus === STATUS_APPROVED) {
        setFieldDisabled(true);
        setReportedToSMDisabled(false);
        setResponseDisabled(false);
        setTimeForResponseDisabled(false);
        setNotesDisabled(false);

        if (userData.role === USER_ROLE_ADMIN && adminEditFields) {
          setFieldDisabled(false);
          setReportedToSMDisabled(false);
          setResponseDisabled(false);
          setTimeForResponseDisabled(false);
          setNotesDisabled(false);
        }
      }
      if (incidentData[1].incidentStatus === STATUS_REJECTED) {
        setFieldDisabled(true);
        setReportedToSMDisabled(true);
        setResponseDisabled(true);
        setTimeForResponseDisabled(true);
        setNotesDisabled(true);
      }
    }
  }, [electionIncidentId, incidentData, userData, recorderByUserId, adminEditFields]);

  useEffect(() => {
    if (incidentData) {
      if (userData.role === USER_ROLE_MONITOR) {
        setShowSaveAction(true);
        setShowEditRejectApproveAction(false);
        setShowAddAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
        if (
          incidentData[1].incidentStatus === STATUS_PENDING &&
          userData.userId !== recorderByUserId
        ) {
          setShowBackAction(true);
          setShowSaveAction(false);
          setShowEditRejectApproveAction(false);
          setShowAddAction(false);
          setShowAddApproveAction(false);
          setShowSaveApproveAction(false);
        }
      } else if (userData.role === USER_ROLE_ADMIN) {
        if (incidentData[1].incidentStatus === STATUS_REJECTED) {
          setShowEditCancelApproveAction(true);
        } else {
          setShowEditRejectApproveAction(true);
        }
        setShowSaveAction(false);
        setShowAddAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);

        if (adminEditFields) {
          setShowSaveApproveAction(true);
          setShowEditRejectApproveAction(false);
          setShowEditCancelApproveAction(false);
          setShowSaveAction(false);
          setShowAddAction(false);
          setShowAddApproveAction(false);
          setShowBackAction(false);
        }
      }

      if (incidentData[1].incidentStatus === STATUS_REJECTED && userData.role !== USER_ROLE_ADMIN) {
        setShowBackAction(true);
        setShowAddAction(false);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddApproveAction(false);
        setShowSaveApproveAction(false);
      }
    } else {
      if (userData.role === USER_ROLE_MONITOR) {
        setShowAddAction(true);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddApproveAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
      } else if (userData.role === USER_ROLE_ADMIN) {
        setShowAddApproveAction(true);
        setShowSaveAction(false);
        setShowEditRejectApproveAction(false);
        setShowAddAction(false);
        setShowBackAction(false);
        setShowSaveApproveAction(false);
      }
    }
  }, [electionIncidentId, incidentData, userData, adminEditFields, recorderByUserId]);

  useEffect(() => {
    setFieldDisabled(false);
    setReportedToSMDisabled(false);
    setResponseDisabled(false);
    setTimeForResponseDisabled(false);
    setNotesDisabled(false);
  }, [adminEditFields]);

  const getDropDownData = async () => {
    const targetCategoryReq = sendAuthenticatedGetRequest(`${API_URL}/targetOptions`);
    const sourceReq = sendAuthenticatedGetRequest(`${API_URL}/sources`);
    const countriesReq = sendGetRequest('https://restcountries.com/v3.1/all');
    const perpetratorGenderReq = sendAuthenticatedGetRequest(`${API_URL}/genders`);
    const languageReq = sendAuthenticatedGetRequest(`${API_URL}/languages`);
    const typeOfContentReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);

    const electionCategoryList = sendAuthenticatedGetRequest(`${API_URL}/electionStages`);
    const electionCategoryTypes = sendAuthenticatedGetRequest(`${API_URL}/electionCategories`);
    const electionComplaintNatures = sendAuthenticatedGetRequest(
      `${API_URL}/electionComplaintNatures`
    );
    const electionCIBs = sendAuthenticatedGetRequest(`${API_URL}/electionCIBs`);
    const electionParties = sendAuthenticatedGetRequest(`${API_URL}/electionParties`);

    await axios
      .all([
        targetCategoryReq,
        sourceReq,
        countriesReq,
        perpetratorGenderReq,
        languageReq,
        typeOfContentReq,
        electionCategoryList,
        electionCategoryTypes,
        electionComplaintNatures,
        electionCIBs,
        electionParties,
      ])
      .then(
        axios.spread(function (
          targetCategoryReq: any,
          sourceReq: any,
          countriesReq: any,
          perpetratorGenderReq: any,
          languageReq: any,
          typeOfContentReq: any,
          electionCategoryList: any,
          electionCategoryTypes: any,
          electionComplaintNatures: any,
          electionCIBs: any,
          electionParties: any
        ) {
          const targetCategoriesResponse = targetCategoryReq.data.targetOptions;

          setCategoryList(electionCategoryList.data.electionStages);
          setCategoryTypes(electionCategoryTypes.data.electionCategories);
          setNatureOfTheComplaintList(electionComplaintNatures.data.electionComplaintNatures);
          setElectionCIBsList(electionCIBs.data.electionCIBs);
          setAffectedParties(electionParties.data.electionParties);
          setContentPublishedSupportOf(electionParties.data.electionParties);

          setTargetCategoryList(targetCategoriesResponse);
          setSourceList(sortArrayAscending(sourceReq.data.sources));
          setCountryList(sortArrayAscending(getCountryNames(countriesReq.data)));
          setPerpetratorGenderList(sortArrayAscending(perpetratorGenderReq.data.genders));
          setLanguageList(sortArrayAscending(languageReq.data.languages));
          setTypeOfContentList(typeOfContentReq.data.socialMediaPlatformContents);

          const targCatInitState: any = {};
          targetCategoriesResponse.forEach((element: any) => {
            targCatInitState[element.targetId] = '';
          });
          setTargetCategoryInitialState(targCatInitState);
        })
      );
  };

  const onChangeProvince = (prov: string) => {
    let province: string = prov;
    if (prov) {
      if (prov === 'North Central') {
        province = 'North_Central';
      }
      if (prov === 'North Western') {
        province = 'North_Western';
      }
      setDistrictList(SRILANKA_DISTRICTS[province]);
    }
  };

  const handleIncidents = (formikProps: FormikProps<any>, status: string = STATUS_NONE) => {
    const { values, errors, submitForm, setSubmitting } = formikProps;
    const errorsExist = Object.keys(errors).length !== 0;

    const targetOptions: any = [];
    const selectedTargetCategories = Object.values(values.targetCategory[0]);
    selectedTargetCategories.forEach(id => {
      targetOptions.push({ targetOptionId: id });
    });

    const categoryTypes: any = [];
    values.categoryTypes.forEach((id: any) => {
      categoryTypes.push({ electionCategoryId: id });
    });

    const affectedParties: any = [];
    values.affectedParties.forEach((id: any) => {
      affectedParties.push({ electionPartyId: id });
    });

    const formData: any = new FormData();

    formData.append('link', values.link);
    formData.append('pageLink', values.pageLink);
    formData.append('description', values.description);
    formData.append('category', values.category);
    formData.append(
      'categoryTypes',
      JSON.stringify(Array.isArray(categoryTypes) ? categoryTypes : [])
    );
    formData.append(
      'affectedParties',
      JSON.stringify(Array.isArray(affectedParties) ? affectedParties : [])
    );

    formData.append('natureOfTheComplaint', values.natureOfTheComplaint);

    // when natureOfTheComplaint is 1. : Violation of Community Standards of Particular Social Media Platform
    formData.append(
      'targetOptions',
      JSON.stringify(
        values.natureOfTheComplaint === 'ELCCMPLNTR926270236312618' ? targetOptions || [] : []
      )
    );
    formData.append(
      'genderBasedViolence',
      values.natureOfTheComplaint === 'ELCCMPLNTR926270236312618'
        ? values.genderBasedViolence || ''
        : ''
    );
    formData.append(
      'genderId',
      values.natureOfTheComplaint === 'ELCCMPLNTR926270236312618'
        ? values.perpetratorGender || ''
        : ''
    );
    formData.append(
      'engagementId',
      values.natureOfTheComplaint === 'ELCCMPLNTR926270236312618' ? values.engagement || '' : ''
    );
    formData.append(
      'views',
      values.natureOfTheComplaint === 'ELCCMPLNTR926270236312618' ? values.views || '' : ''
    );

    formData.append('sourceId', values.source);
    formData.append('date', values.date);
    formData.append('country', values.country);
    formData.append('regionName', values.province);
    formData.append('subRegionCode', values.district);

    formData.append('languageId', values.language);

    formData.append('contentDetectedAsCIB', values.contentDetectedAsCIB);
    formData.append('contentPublishedSupportOf', values.contentPublishedSupportOf);

    formData.append('screenshotId', values.screenshotId);

    formData.append('socialMediaPlatformContentId', values.typeOfContent);

    formData.append('notes', values.notes);
    if (
      values.screenshot &&
      typeof values.screenshot !== 'string' &&
      values.screenshot instanceof File
    ) {
      formData.append('image', values.screenshot);
    }

    if (status && status !== STATUS_NONE && status !== ADD_INCIDENT) {
      formData.append('incidentStatus', status);
    }

    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    submitForm();
    if (!errorsExist) {
      setLoading(true);
      if (status === ADD_INCIDENT) {
        sendAuthenticatedPostRequest(`${API_URL}/election-incident`, formData)
          .then(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/election-incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: ADD_INCIDENT_SUCCESS,
                severity: 'success',
              },
            });
          })
          .catch(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/election-incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: ADD_INCIDENT_FAILED,
                severity: 'error',
              },
            });
          });
      } else {
        sendAuthenticatedPutRequest(`${API_URL}/election-incident/${electionIncidentId}`, formData)
          .then(async () => {
            let historyPushed = false;
            if (showSaveApproveAction) {
              formData.append('incidentStatus', STATUS_APPROVED);
              await sendAuthenticatedPutRequest(
                `${API_URL}/election-incident/${electionIncidentId}`,
                formData
              )
                .then(() => {
                  historyPushed = true;
                  history.push({
                    pathname: '/election-incidents',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: UPDATE_INCIDENT_SUCCESS,
                      severity: 'success',
                    },
                  });
                })
                .catch(() => {
                  historyPushed = true;
                  history.push({
                    pathname: '/election-incidents',
                    state: {
                      toastStatus: TOAST_STATUS,
                      toastMessage: UPDATE_INCIDENT_FAILED,
                      severity: 'error',
                    },
                  });
                });
            }
            setSubmitting(false);
            setLoading(false);
            if (!historyPushed) {
              history.push({
                pathname: '/election-incidents',
                state: {
                  toastStatus: TOAST_STATUS,
                  toastMessage: UPDATE_INCIDENT_SUCCESS,
                  severity: 'success',
                },
              });
            }
          })
          .catch(() => {
            setSubmitting(false);
            setLoading(false);
            history.push({
              pathname: '/election-incidents',
              state: {
                toastStatus: TOAST_STATUS,
                toastMessage: UPDATE_INCIDENT_FAILED,
                severity: 'error',
              },
            });
          });
      }
    }
  };

  const FormikLogger = () => {
    const formik: any = useFormikContext();

    useEffect(() => {
      onChangeProvince(formik.values.province);
    }, [formik.values.province]);

    useEffect(() => {
      if (formik.values.country !== COUNTRY_SRILANKA) {
        formik.setFieldValue('province', '');
        formik.setFieldValue('district', '');
      }
    }, [formik.values.country]);

    return null;
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={hasFormikStateChanged ? incidentData[0] : formikInitialState}
        validationSchema={ElectionIncidentSchema}
        onSubmit={() => {
          //Do nothing
        }}
      >
        {formikProps => {
          const { values, errors, touched, handleChange, setFieldValue, setValues }: any =
            formikProps;
          return (
            <Form className="w-full">
              <FormikLogger />
              {/* Incident Details section. */}
              <div className="border-b-2 border-gray-300 mb-10">
                <span className="font-semibold text-gray-700 text-2xl">Incident Details</span>
                <div className="my-8">
                  <InputField
                    title="Link"
                    isDisabled={fieldDisabled}
                    value={values ? values.link : ''}
                    onChangeHandler={handleChange('link')}
                    isRequired={true}
                    formikError={errors.link}
                    formikTouch={touched.link}
                    containerClassNames="mt-5"
                  />
                  <InputField
                    title="Page/Account Link"
                    isDisabled={fieldDisabled}
                    value={values ? values.pageLink : ''}
                    onChangeHandler={handleChange('pageLink')}
                    isRequired={true}
                    formikError={errors.pageLink}
                    formikTouch={touched.pageLink}
                    containerClassNames="mt-5"
                  />
                  <InputField
                    title="Description"
                    isDisabled={fieldDisabled}
                    value={values ? values.description : ''}
                    onChangeHandler={handleChange('description')}
                    isRequired={true}
                    formikError={errors.description}
                    formikTouch={touched.description}
                    containerClassNames="mt-5"
                  />
                  <div>
                    <FilePicker
                      title="Screenshot"
                      isRequired={true}
                      acceptFileType="image/*"
                      onChangeHandler={(e: any) => {
                        if (e.target.files.length !== 0) {
                          setFieldValue('screenshot', e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));
                        }
                      }}
                      fileName={values ? values.screenshot : ''}
                      isDisabled={fieldDisabled}
                      formikError={errors.screenshot}
                      formikTouch={touched.screenshot}
                      containerClassNames="mt-5 mb-5"
                    />
                    {image ? (
                      <img alt="selected" src={image} className="object-contain w-64 ml-48 pl-5" />
                    ) : (
                      incidentData && (
                        <span className="ml-48 pl-5 text-base italic text-gray-500">
                          Cannot preview image :(
                        </span>
                      )
                    )}
                  </div>
                  <InputField
                    title="Screenshot ID (Please mention the screenshot ID here)"
                    isDisabled={fieldDisabled}
                    value={values ? values.screenshotId : ''}
                    onChangeHandler={handleChange('screenshotId')}
                    isRequired={false}
                    formikError={errors.screenshotId}
                    formikTouch={touched.screenshotId}
                    containerClassNames="mt-5"
                  />

                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <DropDownField
                        title="Category"
                        value={values ? values.category : ''}
                        data={categoryList}
                        onChangeHandler={handleChange('category')}
                        isDisabled={fieldDisabled}
                        isRequired
                      />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <DropDownField
                        title="Category Types"
                        value={values ? values.categoryTypes : []}
                        data={categoryTypes}
                        isDisabled={fieldDisabled}
                        isMultiple={true}
                        onChangeHandler={handleChange('categoryTypes')}
                        isRequired
                      />
                    </Box>
                  </Stack>
                  {/* <DropDownField
                    title="Nature of the Complaint"
                    value={values ? values.natureOfTheComplaint : ''}
                    data={[
                      {
                        id: 1,
                        name: 'Violation of Community Standards of Particular Social Media Platform',
                      },
                      { id: 2, name: 'Violation of Sri Lankan Law' },
                      { id: 3, name: 'Violation of Media Guidelines / Code of Conduct' },
                    ]}
                    isDisabled={fieldDisabled}
                    onChangeHandler={[
                      (e: any) => setNatureOfTheComplaint(e.target.value),
                      handleChange('natureOfTheComplaint'),
                    ]}
                    isRequired={true}
                  /> */}
                  <RadioGroupField
                    title="Nature of the Complaint"
                    value={values?.natureOfTheComplaint || ''}
                    data={natureOfTheComplaintList}
                    isRequired
                    onChangeHandler={[
                      (e: any) => setNatureOfTheComplaint(e.target.value),
                      handleChange('natureOfTheComplaint'),
                    ]}
                    isDisabled={fieldDisabled}
                  />
                  <RadioGroupField
                    title="If the content is detected as Coordinated Inauthentic Behavior (CIB)?"
                    name="contentDetectedAsCIB"
                    value={values?.contentDetectedAsCIB || ''}
                    data={electionCIBsList}
                    onChangeHandler={handleChange('contentDetectedAsCIB')}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                </div>
              </div>

              {/* Target Category Fields */}
              {showTargetCateSection && (
                <div className="border-b-2 border-gray-300 mb-10">
                  <span className="font-semibold text-gray-700 text-2xl">
                    Violation of Community Standards of Particular Social Media Platform
                  </span>
                  <div className="my-8">
                    <div className="flex mt-5 flex-row">
                      <div className="font-medium text-base mr-5 w-48 flex-shrink-0">
                        Target category
                      </div>
                      <div className="w-full">
                        {targetCategoryList.map((category: any, i: any) => {
                          return (
                            <div key={i}>
                              <DropDownField
                                title={category.target}
                                value={
                                  values && values.targetCategory[0][category.targetId]
                                    ? values.targetCategory[0][category.targetId]
                                    : []
                                }
                                data={category.options}
                                containerClassNames={i === 0 ? 'mt-0' : 'mt-5'}
                                isMultiple={true}
                                onChangeHandler={handleChange(
                                  `targetCategory.0.${category.targetId}`
                                )}
                                isDisabled={fieldDisabled}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <RadioGroupField
                      title="Is this an instance of gender-based violence?"
                      name="genderBasedViolence"
                      value={values ? values.genderBasedViolence : ''}
                      data={[
                        { id: 'true', name: 'Yes' },
                        { id: 'false', name: 'No' },
                      ]}
                      onChangeHandler={handleChange('genderBasedViolence')}
                      isDisabled={fieldDisabled}
                    />
                    <RadioGroupField
                      title="Perpetrator Gender (If gender-based violence)"
                      name="perpetratorGender"
                      value={values?.perpetratorGender || ''}
                      data={perpetratorGenderList}
                      onChangeHandler={handleChange('perpetratorGender')}
                      isDisabled={fieldDisabled}
                    />
                    <InputField
                      title="Engagement (Like+Comment+Share)"
                      isDisabled={fieldDisabled}
                      value={values ? values.engagement : ''}
                      onChangeHandler={handleChange('engagement')}
                      formikError={errors.engagement}
                      formikTouch={touched.engagement}
                      containerClassNames="mt-5"
                    />
                    <InputField
                      title="Views (If a Video)"
                      isDisabled={fieldDisabled}
                      value={values ? values.views : ''}
                      onChangeHandler={handleChange('views')}
                      formikError={errors.views}
                      formikTouch={touched.views}
                      containerClassNames="mt-5"
                    />
                  </div>
                </div>
              )}

              {/* To the EC Template*/}
              <div className="border-b-2 border-gray-300 mb-10">
                <span className="font-semibold text-gray-700 text-2xl">To the EC Template</span>
                <div className="my-8">
                  <DropDownField
                    title="Language"
                    value={values ? values.language : ''}
                    data={languageList}
                    onChangeHandler={handleChange('language')}
                    formikError={errors.language}
                    formikTouch={touched.language}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                  <DropDownField
                    title="Source"
                    value={values ? values.source : ''}
                    data={sourceList}
                    onChangeHandler={handleChange('source')}
                    formikError={errors.source}
                    formikTouch={touched.source}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                  <DropDownField
                    title="Affected Party"
                    value={values ? values.affectedParties : []}
                    data={affectedParties}
                    isDisabled={fieldDisabled}
                    isMultiple={true}
                    onChangeHandler={handleChange('affectedParties')}
                  />
                  <DropDownField
                    title="Content Published in Support of"
                    value={values ? values.contentPublishedSupportOf : ''}
                    data={contentPublishedSupportOf}
                    isDisabled={fieldDisabled}
                    onChangeHandler={handleChange('contentPublishedSupportOf')}
                  />
                  <DropDownField
                    title="Type of content"
                    value={values ? values.typeOfContent : ''}
                    data={typeOfContentList}
                    onChangeHandler={handleChange('typeOfContent')}
                    formikError={errors.typeOfContent}
                    formikTouch={touched.typeOfContent}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                  <DatePicker
                    title="Date"
                    inputFormat="dd/MM/yyyy"
                    value={values ? values.date : ''}
                    onChangeHandler={(e: any) => {
                      setFieldValue('date', moment(e).format('YYYY-MM-DD'));
                    }}
                    formikError={errors.date}
                    formikTouch={touched.date}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                  <DropDownField
                    title="Country"
                    value={values ? values.country : ''}
                    data={countryList}
                    onChangeHandler={handleChange('country')}
                    formikError={errors.country}
                    formikTouch={touched.country}
                    isDisabled={fieldDisabled}
                    isRequired
                  />
                  {values && values.country === COUNTRY_SRILANKA && (
                    <div className="flex flex-row">
                      <div className="font-medium text-base mr-5 w-48 flex-shrink-0"></div>
                      <div className="w-full">
                        <DropDownField
                          title="Province"
                          value={values ? values.province : ''}
                          data={SRILANKA_PROVINCES}
                          onChangeHandler={e => {
                            setFieldValue('province', e.target.value);
                            onChangeProvince(e.target.value);
                          }}
                          formikError={errors.province}
                          formikTouch={touched.province}
                          isDisabled={fieldDisabled}
                        />
                        {districtList && (
                          <DropDownField
                            title="District"
                            value={values ? values.district : ''}
                            data={districtList}
                            onChangeHandler={handleChange('district')}
                            formikError={errors.district}
                            formikTouch={touched.district}
                            isDisabled={fieldDisabled}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-b-2 border-gray-300 mb-10">
                <div className="my-8">
                  <div className="flex flex-row mt-5 font-medium text-base ">
                    <div className="flex-shrink-0 mr-5 w-48">Reported by</div>
                    <div>{incidentData ? incidentData[1].reportedByName : userData.name}</div>
                  </div>
                  <TextArea
                    title="Notes"
                    isDisabled={notesDisabled}
                    onChangeHandler={handleChange('notes')}
                    value={values ? values.notes : ''}
                    formikError={errors.notes}
                    formikTouch={touched.notes}
                  />
                </div>
              </div>
              {/* Action Buttons */}
              {(showAddAction || showAddApproveAction) && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/election-incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className={`bg-indigo-500 focus:outline-none ${
                      showAddApproveAction ? 'w-40' : 'w-28'
                    } h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center`}
                    onClick={() => handleIncidents(formikProps, ADD_INCIDENT)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : showAddApproveAction ? (
                      'Add & Approve'
                    ) : (
                      'Add'
                    )}
                  </button>
                </div>
              )}
              {showSaveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/election-incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Save'}
                  </button>
                </div>
              )}
              {showSaveApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/election-incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-40 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Save & Approve'}
                  </button>
                </div>
              )}
              {showEditCancelApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/election-incidents',
                      })
                    }
                    type="button"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => setAdminEditFields(true)}
                    type="button"
                  >
                    Edit
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_APPROVED)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Approve'}
                  </button>
                </div>
              )}
              {showEditRejectApproveAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() => setAdminEditFields(true)}
                    type="button"
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_REJECTED)}
                    type="button"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Reject'}
                  </button>
                  <button
                    className="bg-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-white text-base ml-3 cursor-pointer flex justify-center items-center"
                    onClick={() => handleIncidents(formikProps, STATUS_APPROVED)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress color="inherit" size={20} /> : 'Approve'}
                  </button>
                </div>
              )}
              {showBackAction && (
                <div className="flex justify-end">
                  <button
                    className="border border-indigo-500 focus:outline-none w-28 h-10 rounded-lg text-indigo-500 text-base cursor-pointer"
                    onClick={() =>
                      history.replace({
                        pathname: '/election-incidents',
                      })
                    }
                    type="button"
                  >
                    Back
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ElectionIncidentFormFields;
