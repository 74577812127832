import { SET_ELECTION_INCIDENT_COUNTS_BY_STATUS } from '../../actions/election-incident';

export interface ElectionIncidentsInitialState {
  incidentCounts: any;
}

export const initialState: ElectionIncidentsInitialState = {
  incidentCounts: {},
};

const electionIncidentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ELECTION_INCIDENT_COUNTS_BY_STATUS:
      return { ...state, incidentCounts: action.payload };
    default:
      return state;
  }
};

export default electionIncidentsReducer;
