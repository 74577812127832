import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import InputField from '../../ui/inputField';
import { Link, useHistory } from 'react-router-dom';
import { STATUS_ALL, STATUS_APPROVED, STATUS_PENDING, STATUS_REJECTED } from '../../../Constants';
import useUserData from '../../../services/auth/useUserData';
import DataTable from '../../ui/dataTable';
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import DropDownField from '../../ui/dropdown';
import { FaFilter, FaRedo } from 'react-icons/fa';
import axios from 'axios';
import { API_URL, sendAuthenticatedGetRequest } from '../../../services/http';

const columnData = [
  { field: 'incidentId', headerName: 'ID', filterable: false, sortable: false },
  { field: 'link', headerName: 'Link', flex: 2, filterable: false, sortable: false },
  { field: 'stage', headerName: 'Stage', flex: 2, filterable: false, sortable: false },
  { field: 'source', headerName: 'Source', flex: 1, filterable: false, sortable: false },
  { field: 'language', headerName: 'Language', flex: 1, filterable: false, sortable: false },
  { field: 'postType', headerName: 'Post Type', flex: 1, filterable: false, sortable: false },
  { field: 'reportedBy', headerName: 'Recorded By', flex: 1, filterable: false, sortable: false },
  { field: 'incidentStatus', headerName: 'Status', flex: 1, filterable: false, sortable: false },
];

const Index: React.FC = () => {
  const history = useHistory();
  const { userData } = useUserData();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabType, setTabType] = useState<string>(STATUS_ALL);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [urlParams, setUrlParams] = useState<any>({ sortOrder: 'DESC', sortKey: 'updatedDate' });

  const [statusAllCount, setStatusAllCount] = useState(-1);
  const [statusPendingCount, setStatusPendingCount] = useState(-1);
  const [statusApprovedCount, setStatusApprovedCount] = useState(-1);
  const [statusRejectedCount, setStatusRejectedCount] = useState(-1);

  const counts = useSelector((state: RootState) => state.electionIncidents.incidentCounts);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [stagesList, setStagesList] = useState<any[]>([]);
  const [languageList, setLanguageList] = useState<any[]>([]);
  const [typeOfPostList, setTypeOfPostList] = useState<any[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [selectedStages, setSelectedStages] = useState<any>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState<any>([]);
  const [idFilter, setIdFilter] = useState<string>('');
  const [recordedByFilter, setRecordedByFilter] = useState<string>('');
  const [linkFilter, setLinkFilter] = useState<string>('');

  const getDropDownData = async () => {
    try {
      const categoryReq = sendAuthenticatedGetRequest(`${API_URL}/electionCategories`);
      const sourceReq = sendAuthenticatedGetRequest(`${API_URL}/electionStages`);
      const languageReq = sendAuthenticatedGetRequest(`${API_URL}/languages`);
      const typeOfPostReq = sendAuthenticatedGetRequest(`${API_URL}/socialMediaPlatformContents`);

      await axios.all([categoryReq, sourceReq, languageReq, typeOfPostReq]).then(
        axios.spread(function (
          categoryReq: any,
          sourceReq: any,
          languageReq: any,
          typeOfContentReq: any
        ) {
          setCategoryList(categoryReq.data.electionCategories);
          setStagesList(sourceReq.data.electionStages);
          setLanguageList(languageReq.data.languages);
          setTypeOfPostList(typeOfContentReq.data.socialMediaPlatformContents);
        })
      );
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    if (counts !== undefined) {
      setStatusAllCount(counts.ALL);
      setStatusPendingCount(counts.PENDING);
      setStatusApprovedCount(counts.APPROVED);
      setStatusRejectedCount(counts.REJECTED);
    }
  }, [counts]);

  const rowClickHandler = (row: any) => {
    history.push('/election-incidents/' + row.id);
  };

  const applyFilter = (selectedStatus: string) => {
    let params: any = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (selectedStatus !== STATUS_ALL) {
      params = { sortOrder: 'DESC', sortKey: 'updatedDate', status: selectedStatus };
    }
    if (selectedCategories.length) {
      params.categories = selectedCategories;
    }
    if (selectedStages.length) {
      params.stages = selectedStages;
    }
    if (selectedLanguages.length) {
      params.languages = selectedLanguages;
    }
    if (selectedPostTypes.length) {
      params.postTypes = selectedPostTypes;
    }
    if (recordedByFilter) {
      params.recordedBy = recordedByFilter;
    }
    if (idFilter) {
      params.id = idFilter;
    }
    if (linkFilter) {
      params.link = linkFilter;
    }

    setUrlParams(params);
  };

  const resetFilter = () => {
    setSelectedCategories([]);
    setSelectedStages([]);
    setSelectedLanguages([]);
    setSelectedPostTypes([]);
    setRecordedByFilter('');
    setIdFilter('');
    setLinkFilter('');
    // loads the default data set
    const params: any = { sortOrder: 'DESC', sortKey: 'updatedDate' };
    if (tabType !== STATUS_ALL) {
      params.status = tabType;
    }
    setUrlParams(params);
  };

  const tabChangeHandler = (index: number, selectedStatus: string) => {
    setTabIndex(index);
    setTabType(selectedStatus);
    setSelectedRows([]);
    applyFilter(selectedStatus);
  };

  return (
    <Layout>
      <div>
        <div className="w-full">
          <span className="font-semibold text-gray-700 text-2xl">Election Incidents</span>
          <div className="flex justify-between items-center my-8">
            <div className="grid grid-auto grid-cols-2 lg:grid-cols-4 gap-x-4 w-full">
              <DropDownField
                title="Category"
                data={categoryList}
                value={selectedCategories}
                onChangeHandler={e => setSelectedCategories(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Stage"
                data={stagesList} // Replace with appropriate data source if needed
                value={selectedStages}
                onChangeHandler={e => setSelectedStages(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Language"
                data={languageList}
                value={selectedLanguages}
                onChangeHandler={e => setSelectedLanguages(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <DropDownField
                title="Post Type"
                data={typeOfPostList}
                value={selectedPostTypes}
                onChangeHandler={e => setSelectedPostTypes(e.target.value)}
                containerClassNames="w-70 mb-4"
                isVertical={true}
                isMultiple={true}
                inputClassNames="w-full"
              />
              <InputField
                isHorizontal={false}
                title="ID"
                placeholder="Enter ID"
                value={idFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setIdFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Recorded By"
                placeholder="Enter user name"
                value={recordedByFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setRecordedByFilter(e.target.value)}
              />
              <InputField
                isHorizontal={false}
                title="Link"
                placeholder="Enter link"
                value={linkFilter}
                containerClassNames="w-70 mb-4"
                inputClassNames="w-full"
                onChangeHandler={e => setLinkFilter(e.target.value)}
              />
              <div className="flex items-center justify-end">
                <button
                  className="flex items-center justify-between bg-indigo-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={() => applyFilter(tabType)}
                >
                  Apply Filter
                  <FaFilter className="text-lg pl-1" />
                </button>
                <button
                  className="flex items-center justify-between bg-gray-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3 mt-3"
                  type="button"
                  onClick={resetFilter}
                >
                  Reset
                  <FaRedo className="text-lg pl-1" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center my-8">
            <div>
              <Link to="/election-incidents/add">
                <button
                  type="button"
                  className="bg-green-500 focus:outline-none px-5 h-10 rounded-lg text-white text-base ml-3"
                >
                  Add Incident
                </button>
              </Link>
            </div>
          </div>
          <div className="flex mb-5">
            <button
              onClick={() => tabChangeHandler(0, STATUS_ALL)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 0 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              All {statusAllCount !== -1 && `(` + statusAllCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(1, STATUS_PENDING)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 1 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Pending {statusPendingCount !== -1 && `(` + statusPendingCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(2, STATUS_APPROVED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 2 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Approved {statusApprovedCount !== -1 && `(` + statusApprovedCount + `)`}
            </button>
            <button
              onClick={() => tabChangeHandler(3, STATUS_REJECTED)}
              className={`cursor-pointer focus:outline-none pb-1 mr-4 ${
                tabIndex === 3 ? 'border-b-4 border-indigo-500 text-indigo-500' : ''
              }`}
            >
              Rejected {statusRejectedCount !== -1 && `(` + statusRejectedCount + `)`}
            </button>
          </div>
          {tabType === STATUS_ALL && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_PENDING && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_APPROVED && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              hasCheckbox={true}
              onRowClickHandler={rowClickHandler}
              onRowSelectionHandler={(ids: any) => setSelectedRows(ids)}
              dataFor="ELECTION_INCIDENT"
            />
          )}
          {tabType === STATUS_REJECTED && (
            <DataTable
              url="/election-incident"
              responseProperty={'incidents'}
              rowIdProperty={'id'}
              urlParams={urlParams}
              rowsPerPage={5}
              columnData={columnData}
              onRowClickHandler={rowClickHandler}
              dataFor="ELECTION_INCIDENT"
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Index;
